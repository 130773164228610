import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QueryConstraint,
} from "firebase/firestore";
import { Answer } from "../types/dbTypes";
import { incrementAnswerAmount } from "./user";

const answerConverter = {
  toFirestore: (answer: Answer) => {
    return {
      algorithm: answer.algorithm,
      result: answer.result,
      timestamp: answer.timestamp,
    };
  },
  fromFirestore: (snapshot: any, options: any): Answer => {
    const data = snapshot.data(options);
    return {
      algorithm: data.algorithm,
      result: data.result,
      timestamp: data.timestamp.toDate(),
    };
  },
};

export async function getAnswers(
  id: string,
  amount: number,
  ...constraints: QueryConstraint[]
): Promise<Answer[]> {
  const db = getFirestore();

  const answersRef = collection(db, "users", id, "answers").withConverter(
    answerConverter
  );
  const q = query(
    answersRef,
    orderBy("timestamp", "desc"),
    limit(amount),
    ...constraints
  );
  const querySnapshot = await getDocs(q);

  let result: Answer[] = [];
  querySnapshot.forEach((doc) => {
    result.push(doc.data());
  });

  return result;
}

export async function submitAnswer(
  id: string,
  algorithm: string,
  result: string
): Promise<void> {
  const db = getFirestore();

  const answersRef = collection(db, "users", id, "answers").withConverter(
    answerConverter
  );
  const answer: Answer = {
    algorithm: algorithm,
    result: result,
    timestamp: new Date(),
  };

  await addDoc(answersRef, answer);
  await incrementAnswerAmount(id);
}
