import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Node } from "../types/assistantTypes";
import { Algorithm } from "../types/dbTypes";

const algorithmConverter = {
  toFirestore: (algorithm: Algorithm) => {
    return {
      algorithm: algorithm,
    };
  },
  fromFirestore: (snapshot: any, options: any): Algorithm => {
    const data = snapshot.data(options);
    const algorithm: Node[] = [];
    data.algorithm.forEach((element: Node) => {
      algorithm.push(element);
    });

    return {
      algorithm: algorithm,
      title: data.title,
      description: data.description,
    };
  },
};

export async function getAlgorithm(id: string): Promise<Algorithm> {
  const db = getFirestore();

  const docRef = doc(db, "algorithms", id).withConverter(algorithmConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }

  return {
    algorithm: [],
    title: "",
    description: "",
  };
}
