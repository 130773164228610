import React from "react";
import Result from "../components/Result";
import {
  Stack,
  Box,
  Typography,
  LinearProgress,
  CircularProgress,
  Container,
  Button,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "../hooks";
import { getAuth, User as firebaseUser } from "firebase/auth";

import { loadTree, navigateToNode } from "../redux/assistant/assistantSlice";
import Question from "../components/Question";
import { Node } from "../types/assistantTypes";
import { Algorithm } from "../types/dbTypes";

import { useNavigate, useParams } from "react-router-dom";
import { getAlgorithm } from "../api/algorithm";
import { submitAnswer } from "../api/answers";
import store from "../redux/store";

const defaultNode: Node = {
  id: -1,
  isEnd: true,
  answers: [],
};

function Assistant(): JSX.Element {
  const { algorithm } = useParams();
  const [user, setUser] = React.useState<firebaseUser | null>(null);
  const [algorithmObj, setAlgorithmObj] = React.useState<Algorithm | null>(
    null
  );

  const navigate = useNavigate();
  React.useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) setUser(user);
      else navigate("/login");
    });
  });

  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.assistant);

  React.useEffect(() => {
    if (algorithm) {
      getAlgorithm(algorithm).then((res) => setAlgorithmObj(res));
    } else {
      navigate("/");
    }
  }, [algorithm, navigate]);

  React.useEffect(() => {
    if (algorithmObj) {
      dispatch(loadTree(algorithmObj.algorithm));
    }
  }, [algorithmObj, dispatch]);

  const handleAnswer = (answer: number): void => {
    dispatch(navigateToNode(answer));
    const state = store.getState().assistant; // Get new state
    const currentNode =
      state.questionTree.find((e) => e.id === state.currentNode) || defaultNode;

    if (state.maxStepsLeft === 0 && algorithmObj && user) {
      // If finished already
      const uid = user.uid;

      if (currentNode.result == null) {
        console.error("End point does not have a result!");
        submitAnswer(uid, algorithmObj.title, "Default answer");
      } else {
        submitAnswer(uid, algorithmObj.title, currentNode.result.title);
      }
    }
  };

  const currentNode =
    state.questionTree.find((e) => e.id === state.currentNode) || defaultNode;

  if (algorithmObj == null) {
    return (
      <Container style={{ padding: "5em" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxHeight={1000}
        >
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  return (
    <Box
      component={Stack}
      display="flex"
      justifyContent="center"
      alignItems="stretch"
      direction="column"
      minHeight="100vh"
      padding={5}
    >
      <LinearProgress
        variant="determinate"
        value={((state.maxDepth - state.maxStepsLeft) * 100) / state.maxDepth}
      />
      <Box flexGrow="1">
        {!currentNode.isEnd ? (
          <Question question={currentNode} onAnswer={handleAnswer} />
        ) : (
          <Result result={currentNode.result}/>
        )}
      </Box>
    </Box>
  );
}

export default Assistant;
