import { Stack, ToggleButton, Button, Typography, Divider, Collapse, List, ListItem } from "@mui/material";
import { Node } from "../types/assistantTypes";
import React from "react";
import AlgorithmBulletPoints from "./AlgorithmBulletPoints";

interface Props {
  question: Node;
  onAnswer: (answer: number) => void;
}

const Question: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState<number | null>(null);
  const [showDefinitions, setShowDefinitions] = React.useState(false);

  const answer = () => {
    props.onAnswer(value || -1);
    setValue(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    clickedValue: number | null
  ) => {
    if (value !== clickedValue) setValue(clickedValue);
    else setValue(null);
  };

  return (
    <Stack
      display="flex"
      flexGrow="1"
      height="100%"
      justifyContent="center"
      alignItems="start"
      direction="column"
      px={{ xs: 1, sm: 5, md: 10, lg: 20, xl: 30 }}
      spacing={2}
      padding={5}
    >
      <Typography variant="h6">{props.question.question}</Typography>

      {props.question.bulletPoints && <AlgorithmBulletPoints bulletPoints={props.question.bulletPoints} />}

      {props.question.description &&
        <>
          <Button
            variant="outlined"
            onClick={_ => setShowDefinitions(!showDefinitions)}
          >
            Definiciones
          </Button>

          <Collapse in={showDefinitions}>
            <List>
              {props.question.description.split("*(").map((paragraph, idx) => {
                return (
                  <ListItem key={`paragraph-${idx}`}>
                    <Typography variant="body1">
                      {paragraph}
                    </Typography>
                  </ListItem>
                );
              })
              }
            </List>
          </Collapse>
        </>
      }

      <Stack
        justifyContent="center"
        alignItems="stretch"
        direction="column"
        flexGrow="1"
        spacing={2}
        width="100%"
      >
        {props.question.answers.map((answer, idx) => {
          return (
            <ToggleButton
              value={answer.toNode}
              onClick={handleClick}
              selected={value === answer.toNode}
              key={`answer-${idx}`}
            >
              <Typography fontStyle={{ textTransform: "none" }}>
                {answer.answer}
              </Typography>
            </ToggleButton>
          );
        })}
      </Stack>

      <Button variant="contained" disabled={value === null} onClick={answer} fullWidth>
        Continuar
      </Button>
    </Stack>
  );
};

export default Question;
