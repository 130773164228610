import React from "react";
import { Result } from "../types/assistantTypes";
import { Stack, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/system";
import { ArrowRight } from "@mui/icons-material";

interface ResultProps {
    result: Result | undefined;
}

export default function ResultComponent(props: ResultProps) {

    const { result } = props;
    const [showDose, setShowDose] = React.useState(false);
    const navigate = useNavigate();

    if (result === undefined) return (<></>);

    return (
        <Stack direction="column">
            <Container>
                <Box margin={1} >
                    <Typography padding={3} variant="h6">
                        {result.title}
                    </Typography>
                    <List>
                        {
                            result.bulletPoints.map((paragraph) => (
                                <ListItem>
                                    <ListItemIcon>
                                        <ArrowRight />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body1" fontSize={18} paddingBottom={.5} paddingTop={.5}>
                                            {paragraph}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
            </Container>
            {result.description !== "" &&
                <>
                    <Button
                        variant="outlined"
                        onClick={_ => setShowDose(!showDose)}
                    >
                        Dosis
                    </Button>

                    <Collapse in={showDose}>
                        <List>
                            {result.description.split("\\n").map((paragraph, idx) => {
                                return (
                                    <ListItem key={`paragraph-${idx}`}>
                                        <Typography variant="body1">
                                            {paragraph}
                                        </Typography>
                                    </ListItem>
                                );
                            })
                            }
                        </List>
                    </Collapse>
                </>
            }
            <br></br>
            <Button variant="contained" onClick={() => navigate("/")}>
                Volver a la página principal
            </Button>
        </Stack>
    );
}