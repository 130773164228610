import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getDescriptions } from "../api/algorithm-description";
import { AlgorithmDescription } from "../types/dbTypes";

const AvailableAlgorithmList: React.FC = () => {
  const theme = useTheme();
  const [algorithms, setAlgorithms] = React.useState<
    AlgorithmDescription[] | null
  >(null);

  React.useEffect(() => {
    if (algorithms == null) {
      getDescriptions().then((res) => setAlgorithms(res));
    }
  });

  const navigate = useNavigate();
  const renderAlgorithms = () => (
    <Grid container rowSpacing={4} columnSpacing={2} padding={2}>
      {algorithms &&
        algorithms.map((ans, idx) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            key={`available-algorithm-item-${idx}`}
            textAlign="center"
          >
            <Card style={{ border: `solid ${theme.palette.primary.main} 2px`, backgroundColor: (ans.title.includes('2024')? 'greenyellow' : undefined) }}>
              <CardActionArea onClick={() => navigate(`/assistant/${ans.id}`)}>
                <CardContent>
                  <Typography variant="body1">{ans.title}</Typography>
                  <Typography variant="subtitle1">{ans.description}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
    </Grid>
  );

  return (
    <Paper variant="outlined" style={{ padding: "2em" }}>
      <Typography variant="h3" textAlign="center">
        Nuevo Algoritmo
      </Typography>
      {algorithms == null ? <></> : renderAlgorithms()}
    </Paper>
  );
};

export default AvailableAlgorithmList;
