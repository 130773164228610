import { Node } from "../types/assistantTypes";

export function treeMaxDepth(
  nodes: readonly Node[],
  currentNode: number
): number {
  const currentNodeIdx: number = nodes.findIndex(
    (item) => item.id === currentNode
  );
  if (currentNodeIdx === -1) return -1;

  if (nodes[currentNodeIdx].isEnd) return 0;
  let maxDepth: number = 0;

  for (const ans of nodes[currentNodeIdx].answers) {
    maxDepth = Math.max(maxDepth, treeMaxDepth(nodes, ans.toNode) + 1);
  }

  return maxDepth;
}
