import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import InveraLogo from "../components/InveraLogo";
import { useTheme } from '@mui/material/styles';

function Copyright(props: any) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			Al iniciar sesión, estas aceptando los términos y condiciones de la aplicación
		</Typography>
	);
}

export default function SignIn() {

	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");

	const [emailError, setEmailError] = React.useState("");
	const [passwordError, setPasswordError] = React.useState("");

	const navigate = useNavigate();
	const theme = useTheme();

	React.useEffect(() => {
		getAuth().onAuthStateChanged((next) => {
			if (next != null) {
				navigate("/");
			}
		});
	});

	const signIn = () => {
		const auth = getAuth();
		signInWithEmailAndPassword(auth, email, password).catch((e) => {
			const errorCode: string = e.code;

			if (errorCode === "auth/invalid-email") {
				setEmailError("Email inválido");
			} else if (errorCode === "auth/wrong-password") {
				setPasswordError("Contraseña incorrecta");
			} else {
				setPasswordError(`Error desconocido: $errorCode`);
			}
		});
	};

	const googleSignIn = () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider).catch((e) => {
			setPasswordError(`Error desconocido: ${e.code}`);
		});
	};

	return (
		<>
			<Box
				sx={{
					paddingTop: 6,
					paddingBottom: 6,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					background: 'linear-gradient(90deg, rgba(0,16,255,1) 24%, rgba(113,0,255,1) 72%)',
				}}>
				<InveraLogo width='150px' />
				<Typography sx={{ color: theme.palette.primary.contrastText }} component="h1" variant="h5">
					Algoritmos INVERA
				</Typography>
			</Box>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: 2,
					}}
				>

					<Box sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							error={emailError.length > 0}
							helperText={emailError}
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							error={passwordError.length > 0}
							helperText={passwordError}
							name="password"
							label="Contraseña"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<Button
							fullWidth
							variant="contained"
							sx={{ mt: 3 }}
							onClick={() => signIn()}
						>
							Iniciar Sesión
						</Button>
						<Button
							fullWidth
							variant="outlined"
							sx={{ mt: 1, mb: 2 }}
							startIcon={<img src="g-logo.png" height="25px" alt="Google Logo" />}
							onClick={() => googleSignIn()}
						>
							Ingresar con Google
						</Button>
						<Grid container>
							<Grid item xs>
								<Link component={RouterLink} to="/recover" variant="body2">
									¿Olvidaste tu contraseña?
								</Link>
							</Grid>
							<Grid item>
								<Link component={RouterLink} to="/register" variant="body2">
									Registrarse
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</>
	);
}