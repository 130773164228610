import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Assistant from "./pages/Assistant";
import Login from "./pages/Login";
import Recover from "./pages/Recover";
import Register from "./pages/Register";
import MPCheckout from "./components/Checkout";
import Terms from "./pages/Terms";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/assistant/:algorithm" element={<Assistant />} />
      <Route path="/checkout" element={<MPCheckout />} />
      <Route path="/recover" element={<Recover />} />
      <Route path="/register" element={<Register />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
}

export default App;
