import { Paper, Stack, Typography } from "@mui/material";
import React from "react";

function Terms() {
  return (
    <Stack alignItems="center" direction="column" padding={5}>
      <Paper
        component={Stack}
        direction="column"
        spacing={1}
        paddingX={10}
        paddingY={5}
        sx={{ width: "100%", maxWidth: "md" }}
      >
        <Typography variant="h2" align="center">
          Términos y Condiciones:
        </Typography>

        <Typography>
          Los algoritmos proveeidos en esta página sugieren esquemas empíricos iniciales basados en la evidencia científica publicada hasta junio 2022. No reemplaza el juicio clínico ni los datos de epidemiología local. Los autores no se responsabilizan de la decisión final de tratamiento antimicrobiano guiado por la Web App.
        </Typography>
      </Paper>
    </Stack>
  );
}

export default Terms;
