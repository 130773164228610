import {
  Paper,
  Button,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  Divider,
  Collapse,
} from "@mui/material";
import { startAfter } from "firebase/firestore";
import React from "react";
import { getAnswers } from "../api/answers";
import { Answer } from "../types/dbTypes";

interface Props {
  uid: string;
  algorithmAmount: number;
}

const DataList: React.FC<Props> = (props) => {
  const answerLoadAmount = 5;
  const [answers, setAnswers] = React.useState<Answer[]>([]);

  const loadNewAlgorithms = () => {
    if (answers.length === 0) {
      getAnswers(props.uid, answerLoadAmount).then((answers) =>
        setAnswers(answers)
      );
    } else {
      getAnswers(
        props.uid,
        answerLoadAmount,
        startAfter(answers[answers.length - 1].timestamp)
      ).then((newAnswers) => setAnswers(answers.concat(newAnswers)));
    }
  };

  React.useEffect(loadNewAlgorithms, []);

  return (
    <List sx={{ width: "100%" }}>
      {answers.map((ans, idx) => (
        <React.Fragment key={`algorithm-list-item-${idx}`}>
          {idx > 0 ? <Divider /> : <></>}
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <>
                  <Typography variant="h6">{ans.algorithm}</Typography>
                  <Typography variant="body1">{ans.result}</Typography>
                </>
              }
              secondary={ans.timestamp.toLocaleString()}
            />
          </ListItem>
        </React.Fragment>
      ))}

      {answers.length < props.algorithmAmount ? (
        <ListItem alignItems="center" key="algorithm-list-button">
          <Button fullWidth variant="outlined" onClick={loadNewAlgorithms}>
            Cargar resultados anteriores
          </Button>
        </ListItem>
      ) : (
        <></>
      )}
    </List>
  );
};

const RecentAlgorithmTable: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <Paper variant="outlined" style={{ padding: "2em" }}>
      <Stack direction="column" spacing="1.2em" alignItems="center">
        <Button
          fullWidth
          variant={visible ? "outlined" : "contained"}
          onClick={() => setVisible(!visible)}
        >
          Historial de Algoritmos
        </Button>

        <Collapse in={visible}>
          <DataList {...props} />
        </Collapse>
      </Stack>
    </Paper >
  );
};

export default RecentAlgorithmTable;
