import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { AlgorithmDescription } from "../types/dbTypes";

const algorithmDescriptionConverter = {
  toFirestore: (answer: AlgorithmDescription) => {
    return {
      title: answer.title,
      description: answer.description,
    };
  },
  fromFirestore: (snapshot: any, options: any): AlgorithmDescription => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data.title,
      description: data.description,
    };
  },
};

export async function getDescriptions(): Promise<AlgorithmDescription[]> {
  const db = getFirestore();

  const answersRef = collection(db, "algorithmList").withConverter(
    algorithmDescriptionConverter
  );

  const q = query(answersRef);
  const querySnapshot = await getDocs(q);

  let result: AlgorithmDescription[] = [];
  querySnapshot.forEach((doc) => {
    result.push(doc.data());
  });

  return result;
}
