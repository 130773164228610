import { PersonRounded } from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import React from "react";

interface Props {
  algorithmAmount: number;
  userType: string;
  emailAddr: string;
}

const UserPanel: React.FC<Props> = (props) => {
  return (
    <Paper variant="outlined" style={{ padding: "2em" }}>
      <Grid
        container
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
      >
        <Grid item columns={2}>
          <PersonRounded />
        </Grid>

        <Grid item md={4}>
          Bienvenido, {props.emailAddr}!
        </Grid>

        <Grid item md={3}>
          Tipo de usuario: {props.userType}
        </Grid>

        <Grid item md={3}>
          Algoritmos realizados: {props.algorithmAmount}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserPanel;
