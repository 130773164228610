import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { User } from "../types/dbTypes";

const userConverter = {
  toFirestore: (user: User) => {
    return {
      answers: user.answers,
      userType: user.userType,
      paymentDate: user.paymentDate,
    };
  },
  fromFirestore: (snapshot: any, options: any): User => {
    const data = snapshot.data(options);
    return {
      answers: data.answers,
      userType: data.userType,
      paymentDate: data.paymentDate,
    };
  },
};

export async function getOrCreateUserData(id: string): Promise<User> {
  // If the user document does not exist already, create it as a free user
  const db = getFirestore();

  const docRef = doc(db, "users", id).withConverter(userConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    const user: User = {
      answers: 0,
      userType: "Free",
      paymentDate: new Date(),
    };

    await setDoc(docRef, user);

    return user;
  }
}

export async function incrementAnswerAmount(id: string): Promise<void> {
  const db = getFirestore();

  const docRef = doc(db, "users", id).withConverter(userConverter);

  await updateDoc(docRef, { answers: increment(1) });
}
