import { ArrowRight } from "@mui/icons-material";
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

interface Props {
  features: string[];
  onClick: () => void;
}

const FeatureListAndPurchaseButton: React.FC<Props> = (props) => {
  return (
    <Paper variant="outlined" style={{ padding: "2em" }}>
      <Container>
        <Typography>
          Para poder usar los servicios, es necesario comprar el servicio
          premium con un único pago de $350 a través de MercadoPago. Una vez
          realizado el pago, podrás acceder a los siguientes algoritmos de
          manera inmediata.
        </Typography>

        <List>
          {props.features.map((value) => (
            <ListItem key={value}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText primary={value} />
            </ListItem>
          ))}
        </List>

        <Button fullWidth variant="contained" onClick={props.onClick}>
          Comprar servicio premium
        </Button>
      </Container>
    </Paper>
  );
};

export default FeatureListAndPurchaseButton;
