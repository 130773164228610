import { Logout } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import React from "react";
import InveraLogo from "./InveraLogo";

interface Props {
  onSignout: () => void;
}

const CustomAppBar: React.FC<Props> = (props) => {
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar sx={{ height: 64 }}>
        
        <Box sx={{ flexGrow: 1 }} />

        <IconButton color="inherit" onClick={props.onSignout}>
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
