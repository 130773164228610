import { Paper, Stack, Typography, TextField, Button } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";

function Recover(): JSX.Element {
  const [email, setEmail] = React.useState("");
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  const recover = () => {
    const auth = getAuth();
    auth.useDeviceLanguage();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShouldRedirect(true);
      })
      .catch((e) => console.error(e));
  };

  const navigate = useNavigate();
  React.useEffect(() => {
    if (shouldRedirect) {
      navigate("/login");
    }
  }, [navigate, shouldRedirect]);

  return (
    <Stack alignItems="center" direction="column" padding={5}>
      <Paper
        component={Stack}
        direction="column"
        spacing={1}
        paddingX={10}
        paddingY={5}
        sx={{ width: "100%", maxWidth: "md" }}
      >
        <Typography variant="h3" align="center" sx={{ paddingBottom: 3 }}>
          Recuperar contraseña:
        </Typography>

        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="E-mail"
          variant="outlined"
          type="email"
        />

        <Button variant="contained" onClick={(_e) => recover()}>
          <Typography variant="h5">Recuperar</Typography>
        </Button>
      </Paper>
    </Stack>
  );
}

export default Recover;
