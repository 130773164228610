import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import React from "react";

interface Props {
  bulletPoints: string[];
}

const AlgorithmBulletPoints: React.FC<Props> = (props) => {
  return (
    <List>
      {props.bulletPoints.map((bullet, index) => (
        <ListItem>
          <ListItemIcon>
            <Typography variant="h6" color="black">
              {index + 1}.
            </Typography>
          </ListItemIcon>
          <ListItemText primary={
            <Typography variant="body1">
              {bullet}
            </Typography>
          } />
        </ListItem>
      ))}
    </List>
  );
}

export default AlgorithmBulletPoints;