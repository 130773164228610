import React from 'react'
import { Box } from "@mui/material";

interface Props {
  height?: string;
  width?: string;
}

const InveraLogo: React.FC<Props> = (props) => {
  return (
    <a href="https://invera.org/">
      <img
        src="invera-logo.png"
        alt="Logo de Invera"
        height="100%"
        {...props}
      />
    </a>
  );
};

export default InveraLogo;
