import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { getAuth, User as firebaseUser } from "firebase/auth";
import axios from "axios";
import { Container } from "@mui/system";

declare var MercadoPago: any;

const mp = new MercadoPago("APP_USR-a4049256-b95a-4fbb-a7fc-12a94992c3b6", {
  locale: "es-AR", // The most common are: 'pt-BR', 'es-AR' and 'en-US'
});

function MPCheckout() {
  const [checkoutOpened, setCheckoutOpened] = useState<null | firebaseUser>(
    null
  );

  useEffect(() => {
    const asyncUseEffect = async (user: firebaseUser) => {
      const email = user.email;
      const userId = user.uid;

      if (email == null || userId == null) return;

      const order = await axios.post(
        "https://algoritmos.invera.org/api/mercadopago",
        { email, userId }
      );
      const preferenceId = order.data.preferenceId;

      mp.checkout({
        preference: { id: preferenceId },
        render: {
          container: "#mp-container",
          label: "Pagar",
        },
        mode: "popup",
        autoOpen: true,
      });
    };

    if (checkoutOpened !== null)
      asyncUseEffect(checkoutOpened).catch(console.error);
  }, [checkoutOpened]);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user !== null && !checkoutOpened) {
        setCheckoutOpened(user);
      }
    });
  }, [checkoutOpened]);

  return (
    <div>
      <div id="mp-container"></div>
      <Container>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          style={{ minHeight: "100vh" }}
          spacing={5}
        >
          <Grid item>
            <Typography variant="h4" textAlign="center">
              Se está abriendo el chechout de MercadoPago... Si no se abre, por
              favor habilite ventanas emergentes para este sitio, vuelva a la
              página anterior, y reintente nuevamente.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" textAlign="center">
              15 segundos después de terminado el pago, se habilitará
              automáticamente tu usuario. Si no es habilitado, por favor
              contactar a{" "}
              <a href="mailto:ulilopezpacho@gmail.com">
                ulilopezpacho@gmail.com
              </a>
              , enviando un comprobante del pago.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" textAlign="center">
              Si no se lo ha redirigido luego del pago, haz click{" "}
              <a href="/">aquí</a>.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default MPCheckout;
