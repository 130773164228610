import { CircularProgress, Container, Stack } from "@mui/material";
import { getAuth, User as firebaseUser } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getOrCreateUserData } from "../api/user";
import AvailableAlgorithmList from "../components/AvailableAlgorithmsList";
import CustomAppBar from "../components/CustomAppBar";
import FeatureListAndPurchaseButton from "../components/FeatureListAndPurchaseButton";
import RecentAlgorithmTable from "../components/RecentAlgorithmTable";
import UserPanel from "../components/UserPanel";
import { User } from "../types/dbTypes";

function Home() {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState<firebaseUser | null>(null);
  const [userDoc, setUserDoc] = React.useState<User | null>(null);

  const navigate = useNavigate();
  React.useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) setUser(user);
      else navigate("/login");
    });
  });

  React.useEffect(() => {
    if (user) {
      getOrCreateUserData(user.uid).then((userDoc) => {
        setUserDoc(userDoc);
        setLoading(false);
      });
    }
  }, [user]);

  if (loading || user == null || userDoc == null) {
    return (
      <Container style={{ padding: "5em" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxHeight={1000}
        >
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  return (
    <>
      <CustomAppBar
        onSignout={() => {
          getAuth().signOut();
        }}
      />

      <Container style={{ padding: "0.8em" }}>
        <Stack direction="column" spacing="1.2em">
          <UserPanel
            algorithmAmount={userDoc ? userDoc.answers : 0}
            userType={userDoc ? userDoc.userType : "Free"}
            emailAddr={user && user.email ? user.email : ""}
          />

          {userDoc.userType === "Free" ? (
            <FeatureListAndPurchaseButton
              features={[
                "Algoritmo de Tratamiento Empírico en Pacientes con sospecha de Infección por BGN",
              ]}
              onClick={() => navigate("/checkout")}
            />
          ) : (
            <></>
          )}

          {userDoc.userType === "Premium" || userDoc.answers === 0 ? (
            <AvailableAlgorithmList />
          ) : (
            <></>
          )}

          {userDoc.answers > 0 ? (
            <RecentAlgorithmTable
              uid={user.uid}
              algorithmAmount={userDoc ? userDoc.answers : 0}
            />
          ) : (
            <></>
          )}
        </Stack>
      </Container>
    </>
  );
}

export default Home;
