import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Register(): JSX.Element {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [acceptsTerms, setAcceptsTerms] = React.useState(false);

  const [emailError, setEmailError] = React.useState("");

  const navigate = useNavigate();
  React.useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) navigate("/");
    });
  });

  const confirmPasswordError = () => {
    const isError = confirmPassword.length > 0 && password !== confirmPassword;
    if (isError) {
      return {
        error: true,
        helperText: "Las contraseñas deben ser iguales",
      };
    }
    return {
      error: false,
      helperText: "",
    };
  };

  const register = () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password).catch((e) => {
      console.error(e);
      if (e.code === "auth/email-already-in-use") {
        setEmailError("El e-mail ya está en uso");
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Registración
        </Typography>

        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError.length > 0}
            helperText={emailError}
            label="E-mail"
            variant="outlined"
            type="email"
            autoComplete="email"
            required
          />

          <TextField
            margin="normal"
            fullWidth
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Contraseña"
            variant="outlined"
            type="password"
            autoComplete="password"
            required
            {...confirmPasswordError()}
          />

          <TextField
            margin="normal"
            fullWidth
            autoFocus
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            label="Confirmar Contraseña"
            variant="outlined"
            type="password"
            autoComplete="password"
            required
          />

          <Grid container justifyContent="space-evenly" flexDirection="row">
            <Grid display="flex" flex={2} alignItems="center">
              <Typography>
                Acepta los{" "}
                <Typography
                  component={Link}
                  fontWeight={500}
                  to="/terms"
                  target="_blank"
                >
                  términos, condiciones,
                  <br /> y la política de privacidad
                </Typography>
                :
              </Typography>
            </Grid>

            <Grid display="flex">
              <Checkbox
                checked={acceptsTerms}
                onChange={(e) => setAcceptsTerms(e.target.checked)}
              />
            </Grid>
          </Grid>

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              !acceptsTerms ||
              password !== confirmPassword ||
              password.length === 0
            }
            onClick={(_e) => register()}
          >
            Registrarse
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;
