// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8jFNbrWcpJvrx7JYqo4DlkakKUx-OegA",
  authDomain: "invera-app.firebaseapp.com",
  projectId: "invera-app",
  storageBucket: "invera-app.appspot.com",
  messagingSenderId: "5814047374",
  appId: "1:5814047374:web:bf3586552882b125b7afdf",
  measurementId: "G-M66PC51S3Z",
};

export default function initializeFirebase() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  getAuth(app);
  getFirestore(app);
}
